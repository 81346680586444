// navbar
.fade {
	opacity: 1 !important;
	pointer-events: auto !important;
}

.stop-scrolling {
	height: 100%;
	overflow: hidden;
}

.animate-top-line {
	background-color: #999 !important;
	transform: translateY(-4px) rotate(45deg);
}

.animate-bottom-line {
	background-color: #999 !important;
	transform: translateY(4px) rotate(-45deg);
}

.logo-container{
	opacity: 0;
	animation: fade-in 0.4s ease-in-out 3.8s forwards;
}

.fade-in-down-1{
	transform: translateY(-20px);
	opacity: 0;
	animation: fade-in-down 0.4s ease-in-out 3.9s forwards;
}

.fade-in-down-2{
	transform: translateY(-20px);
	opacity: 0;
	animation: fade-in-down 0.4s ease-in-out 4s forwards;
}

.fade-in-down-3{
	transform: translateY(-20px);
	opacity: 0;
	animation: fade-in-down 0.4s ease-in-out 4.1s forwards;
}

.fade-in-down-4{
	transform: translateY(-20px);
	opacity: 0;
	animation: fade-in-down 0.4s ease-in-out 4.2s forwards;
}

.hero-white-wave-animation{
	width: 100%;
	height: 100%;
	opacity: 0;
	transform: translateY(200px);
	animation: white-wave-fade-in 1s ease-in-out 4.8s forwards;
	overflow: hidden;
}

@keyframes white-wave-fade-in {
    0% {
		opacity: 0;
        transform: translateY(200px);
    }
    100%{
		opacity: 1;
        transform: translateY(10px);
		
    }
}


@keyframes fade-in-down {
    0% {
		opacity: 0;
        transform: translateY(-20px);
    }
    100%{
        transform: translateY(0px);
		opacity: 1;
    }
}

@keyframes fade-in-up {
    0% {
		opacity: 0;
        transform: translateY(20px);
    }
    100%{
        transform: translateY(0px);
		opacity: 1;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

// Intro
@keyframes grow {
    0% {
        bottom: -150px;
    }
    100%{
        bottom: 0px;
    }
}

@keyframes logo-flash {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.8;
    }
    100%{
        opacity: 0;
    }
}

@keyframes shrink {
    0% {
        transform: scale(1);
    }
    100%{
        transform: scale(0);
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}


// planets
@keyframes rotate-planet {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(-50%);
	}
}

@keyframes rotate-planet-reverse {
	0% {
		transform: translateX(-50%);
	}
	100% {
		transform: translateX(0);
	}
}

// Stars
@keyframes shooting-star {
	0% {
		transform: translateX(0);
	}
	2% {
		transform: translateX(-2000px);
	}
	4% {
		transform: translateX(-2000px);
		opacity: 0;
	}
	100% {
		transform: translateX(-2000px);
		opacity: 0;
	}
}

@keyframes flicker {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

// Skills icons animation
@keyframes terminal {
	5% {
		opacity: 1;
	}
	6% {
		opacity: 0;
	}
	7% {
		opacity: 1;
	}
	8% {
		opacity: 0;
	}
	9% {
		opacity: 1;
	}
	10% {
		opacity: 0;
	}
	11% {
		opacity: 1;
	}
	12% {
		opacity: 0;
	}
	13% {
		opacity: 1;
	}
}

@keyframes brush {
	18% {
		transform: scale(1) translate(0);
	}
	20% {
		transform: scale(1.2) rotate(10deg);
	}
	21% {
		transform: scale(1.2) rotate(10deg);
	}
	22% {
		transform: scale(1.2) rotate(40deg);
	}
	23% {
		transform: scale(1.2) rotate(-15deg);
	}
	24% {
		transform: scale(1.2) rotate(10deg);
	}
	25% {
		transform: scale(1) rotate(0);
	}
}

@keyframes user {
	30% {
		transform: scale(1) translate(0);
	}
	32% {
		transform: scale(1.2) rotate(30deg);
	}
	34% {
		transform: scale(1.2) rotate(-400deg);
	}
	36% {
		transform: scale(1.2) rotate(30deg);
	}
	38% {
		transform: scale(1) rotate(0);
	}
}

@keyframes rotate-planet-icon {
	43% {
		transform: translateX(0);
	}
	46% {
		transform: translateX(-50%);
	}
	49% {
		transform: translateX(0);
	}
}

@keyframes flash {
	54% {
		transform: scale(1);
		opacity: 0;
	}
	55% {
		transform: scale(1.2);
		opacity: 1;
	}
	56% {
		transform: scale(1);
		opacity: 0;
	}
	57% {
		transform: scale(1.2);
		opacity: 1;
	}
	58% {
		transform: scale(1);
		opacity: 0;
	}
	59% {
		transform: scale(1.2);
		opacity: 1;
	}
	60% {
		transform: scale(1);
		opacity: 0;
	}
}

@keyframes bulb-on {
	65% {
		opacity: 0;
	}
	66% {
		opacity: 1;
	}
	71% {
		opacity: 1;
	}
	72% {
		opacity: 0;
	}
}

// Projects
.rotate {
	transform: rotateY(180deg);
}

// Section fade-in
.section-fade{
	transform: translateY(50px);
	transition: all 0.8s ease-in-out;
	opacity: 0;
}
.section-fade-in{
	transform: translateY(0px);
	opacity: 1;
}
