.flicker-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 3000px;
	height: 880px;
    opacity: 0.5;

	.flicker {
		position: absolute;
		top: 0;
		left: 0;
		width: 3000px;
	height: 880px;
	}
	.flicker-1 {
		animation: flicker 2s ease infinite;
	}
	.flicker-2 {
		animation: flicker 2s ease 0.7s infinite;
	}
	.flicker-3 {
		animation: flicker 2s ease 1.5s infinite;
	}
}
