@mixin absolute-top {
	position: absolute;
	top: 0;
}

@mixin absolute-top-left {
	@include absolute-top;
	left: 0;
}

@mixin absolute-fill {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

@mixin fixed-fill {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

@mixin center-children-hor {
	display: flex;
	justify-content: center;
	align-items: center;
}

@mixin center-children-vir {
	@include center-children-hor;
	flex-direction: column;
}

@mixin space-hor {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@mixin width-height-of($value) {
	width: $value;
	height: $value;
}

@mixin max-width-height-of($value) {
	max-width: $value;
	max-height: $value;
}

@mixin keep-centered-in-parent {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

@mixin flex-wrap {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}
