@use 'abstracts' as *;

.skills-container {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}

.skill {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin: auto;
	margin-bottom: 80px;
	padding-inline: 50px;

	&:last-child {
		margin-bottom: 0;
	}
}

.skill-art {
	width: 100%;
}

.skill-description {
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	margin: auto;
	margin-block: 40px;

	&:last-child {
		margin-bottom: 0;
	}
}

@media (min-width: 576px) {
	.skill {
		padding-inline: 80px;
	}
	.skill-art {
		width: 100%;
	}
	.skill-description {
		margin-block: 60px;
		width: 450px;
	}
}

@media (min-width: 768px) {
	.skill-art {
		padding-inline: 50px;
	}
}

@media (min-width: 992px) {
	.skills-container {
		display: flex;
		justify-content: center;
	}
	.skill {
		margin-block: 0 !important;
		margin-inline: 45px;
		padding: 0;
		width: 305px;
	}
	.skill-art {
		padding: 0;
		height: 250px;
		width: auto;
	}
	.skill-description {
		width: 100%;
	}
}

// .skill-boxes {
// 	@include flex-wrap;

// 	.skill {
// 		margin-top: 40px;
// 		flex-basis: calc(100% / 3);
// 		min-width: 120px;
// 		@include center-children-vir;
// 	}
// }

// .icon-wrapper {
// 	position: relative;

// 	.icon-animated {
// 		position: absolute;
// 		transform-origin: center;
// 		transform-box: fill-box;
// 	}

// 	.icon-base {
// 		@include width-height-of(80px);
// 	}
// }

// .text-container {
// 	margin: 24px auto 0;
// 	max-width: 230px;
// 	padding-inline: 1rem;

// 	.skill-description {
// 		display: none;
// 	}
// }

// .icon-terminal {
// 	top: 41px;
// 	left: 50px;
// 	width: 12px;
// 	animation: terminal 30s ease-in-out infinite;
// }

// .icon-brush {
// 	top: 17px;
// 	left: 38px;
// 	width: 30px;
// 	animation: brush 30s ease-in-out infinite;
// }

// .icon-user {
// 	top: 23px;
// 	left: 38px;
// 	width: 22px;
// 	animation: user 30s ease-in-out infinite;
// }

// .icon-motion-container {
// 	position: absolute;
// 	top: 20px;
// 	left: 40px;
// 	width: 24px;

// 	.waves-container {
// 		position: absolute;
// 		width: 24px;
// 		height: 24px;
// 		z-index: 1;
// 		overflow: hidden;
// 		border-radius: 50px;
// 		transform: rotate(-10deg);

// 		.motion-waves {
// 			position: absolute;
// 			top: 2px;
// 			left: -2px;
// 			width: 96px;
// 			height: 20px;
// 			opacity: 0.9;
// 			animation: rotate-planet-icon 30s ease-in-out infinite;
// 		}
// 	}
// 	.icon-motion {
// 		position: absolute;
// 		width: 100%;
// 	}
// }

// .icon-flash {
// 	top: 4px;
// 	left: 17px;
// 	width: 50px;
// 	z-index: 1;
// 	opacity: 0;
// 	animation: flash 30s ease-in-out infinite;
// }

// .icon-camera {
// 	position: absolute;
// 	top: 26px;
// 	left: 38px;
// 	width: 22px;
// 	transform-origin: center;
// 	transform-box: fill-box;
// }

// .bulb-on {
// 	top: 9px;
// 	left: 27px;
// 	width: 38px;
// 	z-index: 1;
// 	opacity: 0;
// 	animation: bulb-on 30s ease-in-out infinite;
// }

// .icon-bulb {
// 	position: absolute;
// 	top: 20px;
// 	left: 38px;
// 	width: 16px;
// 	transform-origin: center;
// 	transform-box: fill-box;
// }

// @media (min-width: 768px) {
// 	section.home-skills {
// 		.skill {
// 			margin-top: 64px;

// 			.skill-description {
// 				display: block;
// 			}
// 		}
// 	}
// }
