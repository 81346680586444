.main {
	position: relative;
	width: 100%;
}

.loader {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
	background: transparent;
	margin: 5px auto 0 auto;
	border: solid 2px white;
	border-top: solid 2px var(--orange);
	border-radius: 50%;
	opacity: 0;
	transition: opacity 0.5s ease-in-out;
}

.check {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	transform: translate3d(-4px, 20px, 0);
	opacity: 0;
	
	span:nth-child(1) {
		display: block;
		width: 10px;
		height: 2px;
		background-color: var(--green);
		transform: translateY(-70px) translateX(-2px) rotate(45deg);
	}
	span:nth-child(2) {
		display: block;
		width: 20px;
		height: 2px;
		background-color: var(--green);
		transform: translateY(-70px) translateX(-2px) rotate(-45deg)
			translate3d(14px, -4px, 0);
		transform-origin: 100%;
	}
}

.exclamation {
	width: 100%;
	display: flex;
	justify-content: center;
	position: absolute;
	bottom: 16px;
	color: var(--red);
	transition: opacity 0.05s ease-in;
	opacity: 0;
}

.loader.active {
	animation: loading 0.6s linear infinite;
	opacity: 1;
}

.loader.success {
	transition: border-color 0.3s ease-in;
	border-color: var(--green);
}

.loader.failed {
	transition: border-color 0.1s ease-in;
	border-color: var(--red);
}

.check.active {
	opacity: 1;
	transform: translate3d(-4px, -12px, 0);
	transition: all 0.5s cubic-bezier(0.49, 1.74, 0.38, 1.74);
	transition-delay: 0.5s;
}

.exclamation.active {
	opacity: 1;
}

.errors {
	animation: errors 0.4s ease-in-out;
}

@keyframes errors {
	0% {
		transform: translateX(0px);
	}
	25% {
		transform: translateX(30px);
	}
	50% {
		transform: translateX(-30px);
	}
	75% {
		transform: translateX(30px);
	}
	100% {
		transform: translateX(0px);
	}
}

@keyframes loading {
	from {
		-ms-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	to {
		-ms-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
