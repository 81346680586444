@use 'abstracts' as *;

.projects-container {
	@include center-children-vir;
	position: relative;
	width: 100%;
	padding-top: 2rem;
	z-index: 0;
	margin-top: 25px;
}

.project {
	@include center-children-hor;
	position: relative;
	transition: all 0.6s ease-in-out, opacity 0.3s ease-in-out;
	cursor: pointer;
	transform-style: preserve-3d;
	margin-block: 1rem;
	background: transparent;

	.logo-container {
		@include width-height-of(60%);
		@include center-children-hor;
		
		position: absolute;
		pointer-events: none;
		transform: translateZ(var(--z-offset));
		backface-visibility: hidden;
		-webkit-backface-visibility: hidden;

		.logo {
			@include width-height-of(100%);
			
		}

		.mf-logo{
			width: 100%;
		}

		.virtical-logo{
			height: 100%;
		}
	}

	.back::before {
		@include width-height-of(90%);
		position: absolute;
		content: '';
		border: 2px solid var(--gray-200);
		border-radius: 25px;
		pointer-events: none;
		transform: translateZ(var(--z-offset));
		backface-visibility: hidden;
		-webkit-backface-visibility: hidden;
	}
}

.project-rotater {
	@include center-children-hor;
	width: 100%;
	height: 100%;
	background-color: white;
	pointer-events: none;
	transform-style: preserve-3d;
	transition: all 0.6s ease-in-out, opacity 0.3s ease-in-out;
	box-shadow: 0px 0px 50px 10px rgba(40, 40, 40, 0.1);
	border-radius: 25px;

	&::before {
		@include width-height-of(90%);
		position: absolute;
		content: '';
		border: 2px solid var(--gray-200);
		border-radius: 25px;
		pointer-events: none;
		transform: translateZ(var(--z-offset));
		backface-visibility: hidden;
		-webkit-backface-visibility: hidden;
	}
}

.left-project,
.right-project {
	z-index: 0;
	@include max-width-height-of(480px);
	@include width-height-of(var(--side-box-width-height));
}

.center-project {
	transform: translateZ(100px);
	z-index: 2;
	@include max-width-height-of(480px);
	@include width-height-of(var(--center-box-width-height));
}

.back {
	@include center-children-vir;
	@include width-height-of(100%);
	transform: rotateY(180deg) translateZ(var(--z-offset));
	pointer-events: none;
	position: relative;
	font-size: 100vw;
	transition: all 0.6s ease-in-out;
	backface-visibility: hidden;
	-webkit-backface-visibility: hidden;

	.btn-default {
		margin-bottom: 0;
		margin-top: 1.5em;
		pointer-events: auto;
		font-size: 0.02em;
		width: 18em;
		height: 4em;
	}

	&::before {
		transition: all 0.6s ease-in-out;
	}
}

.back-title {
	font-size: 0.05em;
	padding-bottom: 1em;

	&::before {
		top: 1.2em;
		width: 2em;
		background-color: var(--pink);
	}
}

.back-description {
	font-size: 0.03em;
	margin: 0;
	margin-bottom: 0.5em;
	padding-inline: 10%;
}

.tools-container {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	width: 0.5em;
	height: 0.24em;

	.tool {
		@include center-children-vir;
		margin: auto;
		align-items: center;

		.tool-logo {
			img {
				width: 100%;
			}

			.logo {
				height: 0.1em;
			}
		}

		.tool-name {
			margin-top: 1em;
			font-size: 0.03em;
			white-space: nowrap;
		}
	}
}

@media (min-width: 576px) {
	:root {
		--side-box-width-height: 28vw;
		--center-box-width-height: 34vw;
		--container-height: 45vw;
	}

	.projects-container {
		margin-top: 0;
		flex-direction: row;
		height: calc(min(576px, var(--container-height)) + 60px);
		padding-top: 0;

		.project {
			margin-block: 0;
			position: absolute;
			// transform: translateX(0);
		}

		.project-rotater {
			box-shadow: 0px 0px 50px 10px rgba(40, 40, 40, 0.1);
		}

		.left-project,
		.right-project {
			.back {
				transform: rotateY(180deg) translateZ(var(--z-offset))
					scale(0.79);
				transition: all 0.6s ease-in-out;

				&::before {
					transform: translateZ(var(--z-offset)) scale(1.27);
				}
			}
		}

		.left-project {
			@include max-width-height-of(384px);
			transform: translateX(
					calc(min(384px, var(--side-box-width-height)) * -1)
				)
				translateZ(-100px);
		}

		.right-project {
			@include max-width-height-of(384px);
			transform: translateX(
					calc(min(384px, var(--side-box-width-height)))
				)
				translateZ(-100px);
		}

		.back-title {
			font-size: 0.02em;
			padding-bottom: 1em;

			&::before {
				top: 1.5em;
				width: 2em;
				background-color: var(--pink);
			}
		}

		.back-description {
			font-size: 0.012em;
			width: 25em;
			padding-inline: 5%;
		}

		.tools-container {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			width: 0.22em;
			height: 0.08em;
		}

		.tool {
			.tool-logo .logo {
				height: 0.035em;
			}

			.tool-name {
				font-size: 0.012em;
			}
		}

		.back .btn-default {
			margin-bottom: 0;
			margin-top: 1.5em;
			pointer-events: auto;
			font-size: 0.01em;
			width: 15em;
			height: 3.5em;
		}

		.btn-underline{
			margin-top: 1.5em;
		}
	}
}

@media (min-width: 768px) {
	:root {
		--side-box-width-height: 28vw;
		--center-box-width-height: 34vw;
		--container-height: 45vw;
	}

	.projects-container {
		height: calc(min(576px, var(--container-height)) + 80px);
	}
}

@media (min-width: 1200px) {
	:root {
		--side-box-width-height: 20vw;
		--center-box-width-height: 25vw;
		--container-height: 30vw;
	}

	.projects-container {
		.back-title {
			font-size: 0.014em;
			padding-bottom: 1em;

			&::before {
				top: 1.2em;
				width: 2em;
				background-color: var(--pink);
			}
		}

		.back-description {
			font-size: 0.009em;
			width: 25em;
			padding-inline: 5%;
		}

		.tools-container {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			width: 0.16em;
			height: 0.07em;
		}

		.tool {
			.tool-logo .logo {
				height: 0.0275em;
				
			}

			.tool-name {
				font-size: 0.009em;
			}
		}

		.back .btn-default {
			margin-bottom: 0;
			margin-top: 1em;
			pointer-events: auto;
			font-size: 0.007em;
			width: 14em;
			height: 3.2em;
		}
	}
}

@media (min-width: 1920px) {
	.back{
		font-size: 1920px;
	}

	.project .logo-container, .project .back::before, .project-rotater::before  {
		transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 134.4, 1);
	}

	.back{
		transform: rotateY(180deg) matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 134.4, 1);
	}

}