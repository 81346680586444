@use 'abstracts' as *;

section.home-contact-container {
	display: flex;
	position: fixed;
	z-index: 6;
	width: 100vw;
	height: 100vh;
	pointer-events: none;
}

.contact-bg {
	position: absolute;
	width: 100vw;
	height: 100vh;
	left: 0;
	background-color: black;
	opacity: 0;
	pointer-events: none;
	transition: opacity 0.5s ease-in-out;
}

.contact-container {
	@include center-children-vir;
	position: relative;
	margin: auto;
	height: 100vh;
	width: 100vw;
	max-width: 640px;
	max-height: 820px;
	background-color: white;
	box-shadow: 0px 0px 40px 10px rgba(0, 0, 0, 0.2);
	transition: all 0.5s ease-in-out;

	&::before {
		position: absolute;
		display: none;
		content: '';
		top: 20px;
		left: 20px;
		right: 20px;
		bottom: 20px;
		border-radius: 35px;
		border: solid 2px rgb(240, 240, 240);
		z-index: 1;
		pointer-events: none;
	}
}

.contact-content-wrapper{
	position: relative;
	display: flex;
	justify-content: center;
	padding-inline: 10px;
	padding-top: 80px;
	padding-bottom: 30px;
	height: 100%;
	width: 100%;
	overflow-y: scroll;
}

.hide-contact {
	transform: translateX(40vw);
	opacity: 0;
}

.show-bg {
	opacity: 0.5 !important;
	pointer-events: all !important;
}

.slide-in {
	opacity: 1 !important;
	transform: translateX(0);
	pointer-events: all !important;
	right: auto !important;
}

.close-contact-button {
	@include center-children-hor;
	background-color: transparent;
	cursor: pointer;
	position: absolute;
	width: 30px;
	height: 30px;
	top: 5%;
	right: 10%;
	border: none;
	color: var(--gray-400);
	font-size: 30px;

	.lines-wrapper {
		height: 27px;
	}
	.top-line,
	.bottom-line {
		background-color: var(--gray-400);
		height: 5px;
	}

	.top-line {
		transform: rotate(45deg);
	}

	.bottom-line {
		transform: rotate(-45deg);
	}
}

form {
	@include center-children-vir;
	height: 650px;
	width: 80%;
	overflow: hidden; // MAYBE
	padding-inline: 6px;

	.contact-title{
		margin-top: 5px;
		font-size: 25px;
	}

	h2::before {
		background-color: var(--pink);
	}

	p {
		font-family: var(--secondary-font);
		color: var(--gray);
		font-size: 15px;
		margin-bottom: 0;
	}

	.g-recaptcha {
		margin-top: 35px;
		transform: scale(0.65);
	}
}

.send-button {
	padding: 0;
	width: 100%;
	max-width: 220px;
	margin: auto;
	i {
		font-size: 20px;
		position: absolute;
		width: 120px;
		display: flex;
		justify-content: left;
	}
}

.blobs {
	position: absolute;
	height: 920px;
	z-index: 2;
	pointer-events: none;
	transform: translate(-30px, -10px);
	opacity: 0;
	transition: all 0.3s ease;
}

.blob-shadows {
	position: absolute;
	height: 990px;
	pointer-events: none;
	z-index: 1;
	transform: translate(-15px, 10px);
	filter: blur(5px);
	opacity: 0;
	transition: all 0.3s ease;
}


@media (min-width: 320px) {
	.contact-title{
		font-size: 30px;
	}

	.contact-content-wrapper{
		padding-inline: 20px;
		padding-top: 100px;
		padding-bottom: 50px;
	}

	.g-recaptcha {
		transform: scale(0.7) !important;
	}
}

@media (min-width: 420px) {
	.contact-container::before {
		display: block;
	}

	.g-recaptcha {
		transform: scale(1) !important;
	}
}

@media (min-width: 576px) {
	.close-contact-button {
		top: 10%;
		right: 20%;
	}
	.contact-container, .contact-content-wrapper{
		border-radius: 40px;
	}

	.contact-container::before {
		top: 35px;
		left: 35px;
		right: 35px;
		bottom: 35px;
	}
	
	.block-test-top, .block-test-bottom{
		height: 35px;
	}
	
	.blob-shadows {
		opacity: 0.5;
	}
	.blobs {
		opacity: 1;
	}
}

@media (min-height: 750px) {
	.contact-container::before{
		display: block;
	}

	.contact-content-wrapper{
		overflow-y: hidden;
	}
}