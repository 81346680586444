@use 'abstracts' as *;

.hero-stars {
	@include absolute-top;
	width: 3000px;
	height: 950px;
	pointer-events: none;
	overflow: hidden;

	.stars {
		@include width-height-of(100%);
		@include keep-centered-in-parent;
	}
}
