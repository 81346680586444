@use 'abstracts' as *;

section.home-about {
	padding-bottom: 100px;
	padding-top: var(--sections-padding-block);
	padding-top: 150px;
}

.home-about-container {
	max-width: 1300px;
	margin-inline: auto;
	padding-inline: 50px;
	padding-top: calc(var(--sections-padding-block) - 45px);
}

.info-and-portrait-container {
	@include center-children-hor;
	display: flex;
	flex-direction: column-reverse;
	margin-top: 4rem;
}

.left {
	@include center-children-vir;
	margin-top: 40px;
}

.about-site-and-logos-container {
	@include center-children-hor;
	display: flex;
	flex-direction: column;
	margin-top: 250px;
}

.right {
	@include center-children-vir;
	margin-top: 40px;
}

.about-desc-title {
	text-align: left;
	font-size: 20px;
	font-weight: 600;
}

.about-desc {
	font-size: 15px;
	text-align: left;
	line-height: 2rem;
	text-align: center;
	margin-top: 0px;

}

.resume-btn {
	margin: auto;
}


.img-container {
	width: 100%;

	img {
		width: 100%;
	}
}

@media (min-width: 576px) {
	section.home-about {
		padding-bottom: 188px;
	}

	.home-about {
		padding-top: 0px;
	}

	.home-about-container {
		padding-inline: 80px;
	}

	.about-desc {
		line-height: 2.5rem;
	}

	.img-container {
		width: 90%;
	}
}

@media (min-width: 992px) {
	.info-and-portrait-container {
		flex-direction: row;
		margin-top: 80px;
	}

	.left {
		padding-right: 100px;
		text-align: center;
		width: 100%;
		margin-block: 0;
	}

	.about-site-and-logos-container {
		flex-direction: row;
		margin-top: 180px;
	}

	.right {
		padding-left: 100px;
		text-align: center;
		width: 100%;
		margin-block: 0;
	}

	.resume-btn {
		margin: 0px;
	}

	.about-desc-title {
		width: 100%;
		margin: 0;
		text-align: left;
	}

	.about-desc {
		text-align: left;
	}

	.img-container {
		min-width: 200px;
		width: 80%;
	}
}
