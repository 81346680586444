@use '../abstracts' as *;

.btn-default {
	@include center-children-hor;
	font-family: var(--secondary-font);
	color: var(--white);
	width: 220px;
	height: 52px;
	border-radius: 50px;
	font-weight: 600;
	font-size: 15px;
	text-decoration: none;
	border: none;
	margin-block: 1rem;
	letter-spacing: 1.54px;
	text-transform: uppercase;
	cursor: pointer;
	transition: all 0.5s;
	white-space: nowrap;

	&:hover {
		transform: scale(1.05);
	}
}

.btn-underline {
	@include center-children-hor;
	font-family: var(--secondary-font);
	color: #626881;
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;
	margin-top: 14px;
	font-size: 0.02em;
	line-height: 1.5;
	letter-spacing: 0.5px;
	color: #626881;
	text-align: center;
	pointer-events: auto;
	transition: all 0.2s;

	border-bottom: 1px solid #626881;
	&:hover {
		color: var(--pink);
		border-color: var(--pink);;
	}
}

.purple {
	background-color: var(--purple);
}

.pink {
	background-color: var(--pink);
}

.orange {
	background-color: var(--orange);
}

.gray{
	background-color: var(--gray-500);
	cursor: default;
	&:hover {
		transform: none;
	}
}

.nav-button {
	color: #999;
	font-family: Montserrat, sans-serif;
	font-weight: 500;
	font-size: 0.7rem;
	text-transform: uppercase;
	letter-spacing: 1px;
	cursor: pointer;
	text-align: center;
	padding-block: 0.75rem;
	cursor: pointer;
	transition: color 0.2s ease-in-out;

	&:hover{
		color: var(--pink);
	}
}

.mobile-menu-button {
	@include center-children-hor;
	@include width-height-of(32px);
	cursor: pointer;
	z-index: 1;
}

.lines-wrapper {
	position: relative;
	width: 32px;
	height: 18px;
	font-size: 2rem;
	z-index: 1;
}

.top-line,
.bottom-line {
	position: absolute;
	left: 0;
	width: 31px;
	height: 4px;
	border-radius: 25px;
	background-color: white;
	transition: all 0.3s ease-in-out;
	transform-origin: 0% 50%;
}

.top-line {
	top: 0;
}

.bottom-line {
	bottom: 0;
}

@media (min-width: 768px) {
	.nav-button {
		margin-left: 1.5rem;
		color: white;

		&:first-child {
			margin-left: 0;
		}
	}

	.mobile-menu-button {
		display: none;
	}
}

@media (min-width: 576px) {
	.btn-underline {
		font-size: 0.007em;
	}
}