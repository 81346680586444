$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$grays: (
	'100': $gray-100,
	'200': $gray-200,
	'300': $gray-300,
	'400': $gray-400,
	'500': $gray-500,
	'600': $gray-600,
	'700': $gray-700,
	'800': $gray-800,
	'900': $gray-900,
);

$blue: #0d6efd;
$dark-blue: #0a1b7c;
$indigo: #671d79;
$purple: #6f42c1;
$pink: #ff5e7e;
$red: #dc3545;
$orange: #f5a40e;
$yellow: #ffc107;
$green: #2cb455;
$teal: #20c997;
$cyan: #0dcaf0;

$colors: (
	'blue': $blue,
	'dark-blue': $dark-blue,
	'indigo': $indigo,
	'purple': $purple,
	'pink': $pink,
	'red': $red,
	'orange': $orange,
	'yellow': $yellow,
	'green': $green,
	'teal': $teal,
	'cyan': $cyan,
	'white': $white,
	'gray': $gray-600,
	'gray-dark': $gray-800,
);

$primary: $blue;
$secondary: $gray-600;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-900;
$gradient: linear-gradient(to top right, $indigo, $dark-blue);

$theme-colors: (
	'primary': $primary,
	'secondary': $secondary,
	'success': $success,
	'info': $info,
	'warning': $warning,
	'danger': $danger,
	'light': $light,
	'dark': $dark,
	'gradient': $gradient,
);
