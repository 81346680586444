@use '../abstracts' as *;

:root {
	--header-height: 64px;
	--hero-height: 950px;
	--hero-width: 3000px;
	--primary-font: 'lemon-milk';
	--secondary-font: Montserrat, sans-serif;
	--sections-padding-block: 90px;
	--side-box-width-height: 80vw;
	--center-box-width-height: 80vw;
	--container-height: fit-conetent;
	--z-offset: 20vw;
	--animation-start: 0.6s;

	@each $size, $value in $breakpoints {
		--breakpoint-#{$size}: #{$value};
	}

	@each $color, $value in $colors {
		--#{$color}: #{$value};
	}

	@each $color, $value in $grays {
		--gray-#{$color}: #{$value};
	}

	@each $color, $value in $theme-colors {
		--#{$color}: #{$value};
	}
}

@media (min-width: 576px) {
	:root {
		--z-offset: 7vw;
	}
}
