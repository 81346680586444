@use 'abstracts' as *;

.error {
	span {
		color: var(--red);
	}
	&::before {
		border-bottom: 1px solid var(--red) !important;
	}
}

.input-container {
	font-family: var(--secondary-font);
	color: var(--gray);
	margin-top: 20px;
	width: 100%;
	height: 40px;
	position: relative;
	font-size: 15px;

	span {
		@include center-children-hor;
		height: 100%;
		position: absolute;
		left: 8px;
		font-size: inherit;
	}

	input,
	.input {
		@include center-children-hor;
		padding: 8px;
		padding-left: 85px;
		display: flex;
		width: 100%;
		height: 100%;
		font-size: inherit;
		font-family: inherit;
		letter-spacing: 0.5px;
		position: absolute;
		border-radius: 5px;
		outline: none;
		color: inherit;
		

		letter-spacing: 0.5px;
		border: none;
		resize: none;
		background-color: transparent;

		&:focus {
			box-shadow: 0 0 3pt 2pt cornflowerblue;
		}

	}

	&::before {
		content: '';
		position: absolute;
		bottom: 0px;
		width: 100%;
		border-bottom: 1px solid rgb(200, 200, 200);
	}
}

@media (min-width: 458px) {
	.input-container::before {
		bottom: -1px;
	}
}

.textarea-container {
	height: 120px;

	span {
		width: calc(100% - 18px);
		padding: 8px;
		padding-bottom: 0;
		height: auto;
		// background-color: white;
		z-index: 1;
		left: 0;
		top: 1px;
		border-radius: 5px;
		justify-content: flex-start;
	}

	.textarea {
		height: 100%;
		padding-top: 35px;
		padding-left: 8px;
	}
}
