section.home-skills {
	padding-top: calc(var(--sections-padding-block) + 20px);
	padding-bottom: var(--sections-padding-block);
	width: 100%;

	.home-skills-container {
		max-width: 1300px;
		// padding-inline: 25px;
		margin-inline: auto;
		width: 100%;
		// background-color: green;

		.skills-and-title-container {
			position: relative;
			width: 100%;
			max-width: 1200px;
			margin: 0 auto;
			//background-color: yellow;
		}
	}
}
