@use 'abstracts' as *;

.hero-pattern-bg {
	@include keep-centered-in-parent;
	pointer-events: none;
	width: 3000px;
	height: 950px;
	z-index: 1;

	.hero-white-wave {
		@include keep-centered-in-parent;
		@include width-height-of(100%);
		bottom: 0px;
	}
}
