@use 'abstracts' as *;

section.home-hero {
	@include keep-centered-in-parent;
	@include center-children-hor;
	overflow-x: hidden;
    overflow-y: hidden;
	position: relative;
	max-width: var(--hero-width);
	height: var(--hero-height);
	background-image: var(--gradient);
}

.hero-text {
	z-index: 1;
	opacity: 0;
    transform: translateY(20px);
    animation: fade-in-up 1s ease-in-out 4.5s forwards;

    .heading{
        font-size: 50px;
    }

	.sub-heading {
		font-family: var(--secondary-font);
		font-weight: 400;
		font-size: 30px;
		color: white;
		margin-block: 1.25rem;
		text-align: center;
		margin-inline: 1rem;
	}

	.btn-default {
		margin: 0px auto 150px auto;
	}
}

// Logo animation
.intro-container-wrapper{
    position: absolute;
    width: 100%;
	height: 100%;
    max-height: 950px;
    display: flex;
    justify-content: center;
}
.intro-container{
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 3;
	width: 100%;
	height: 100vh;
	background-color: rgba(0,0,0,0.3);
	animation: fade-out 0.4s ease-in-out forwards;
	animation-delay: 3.2s;
}

.animated-logo-container{
    position: relative;
    width: 300px;
    height: 134px;
    animation: shrink 0.4s ease-in-out forwards;
    animation-delay: 3s;
    
}

@media (min-height: 500px) {
    .intro-container {
        max-width: 3000px;
        max-height: 950px;
    }

    .animated-logo-container{
        margin-bottom: 50px;
    }
  }

.animated-logo-wrapper{
    transform: scale(0.5);
}

.animated-logo{
    opacity: 0;
	width: 300px;
    filter: blur(10px);
    animation: logo-flash 0.8s ease-in-out;
    animation-delay: 2s;
}

.pillar-container{
    position: absolute;
    overflow: hidden;
    width: 35px;
    height: 150px;
    border-radius: 50px;
}

.pillar-filler{
    background-color: white;
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: -150px;
    border-radius: 50px;
}

.pillar-1{
    left: -9px;
    bottom: 2px;
    transform-origin: center bottom;
    transform: rotate(30deg);
}

.filler-1{
    animation: grow 0.6s ease-in-out forwards;
    animation-delay: var(--animation-start);
}

.pillar-2{
    transform: rotate(150deg);
    left: 87px;
    top: -8px;
}

.filler-2{
    animation: grow 0.3s ease-in-out forwards;
    animation-delay: calc(var(--animation-start) + 0.6s);
}

.pillar-3{
    transform: rotate(150deg);
    left: 160px;
    top: -8px;
}

.filler-3{
    animation: grow 0.3s ease-in-out forwards;
    animation-delay: calc(var(--animation-start) + 0.7s);
}

.pillar-4{
    transform: rotate(150deg);
    left: 236px;
    top: -8px;
}

.filler-4{
    animation: grow 0.3s ease-in-out forwards;
    animation-delay: calc(var(--animation-start) + 0.8s);
}

@media (min-width: 320px) {
    .hero-text .heading{
        font-size: 70px;
    }
}

@media (min-width: 768px){
    .animated-logo-wrapper{
        transform: scale(1);
    }
}