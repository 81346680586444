@use 'abstracts' as *;

.scroll-buttons-container {
	@include space-hor;
	position: absolute;
	bottom: 0;
	width: 150px;
	display: none;

	.scroller {
		@include width-height-of(50px);
		@include center-children-hor;
		font-size: 3rem;
		cursor: pointer;
		transition: all 0.2s;
		color: var(--dark);

		&:hover {
			background-color: #eee;
		}
	}
}

@media (min-width: 576px) {
	.scroll-buttons-container {
		display: flex;
	}
}
