@use 'abstracts' as *;

.hero-bg-swirls {
	@include absolute-top;
	@include keep-centered-in-parent;
	pointer-events: none;

	img {
		opacity: 0.1;
		width: var(--hero-width);
		height: var(--hero-height);
	}
}
