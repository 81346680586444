@use 'abstracts' as *;

.additional-projects-section{
    @include center-children-vir;
    position: relative;
    max-width: 1300px;
	margin-inline: auto;
	padding-inline: 50px;
    width: 100%;
}

.more-projects-text{
    position: absolute;
    white-space: nowrap;
    top: -18px;
    color: $gray-900;
    font-size: 12px;
    font-weight: 500;
}

.additional-project-button{
    @include center-children-vir;
    @include width-height-of(50px);
    position: absolute;
    bottom: -80px;
    
    font-size: 3rem;
    cursor: pointer;
    
	color: var(--dark);
    border-radius: 3px;
    background-color: transparent;
    // overflow: hidden;
    &:hover {
        background-color: #eee;
    }
}

.icon-wrapper{
    overflow: hidden;
    height: 20px;
    margin-top: 4px;
    transition: all 0.3s ease-in-out;
    .fa-caret-down{
        pointer-events: none;
        transform: translateY(-26px);
    }
}

.additional-projects-section {
    .section-title{
        padding: 0;
        padding-top: 8px;
        height: 30px;
        margin-bottom: 20px;
        margin-top: 10px;
    
        &::before{
            margin-top: 8px
        }
    }

}

.additional-projects-container{
    @include center-children-vir;
    width: 100vw;
    max-height: 0px;
    overflow: hidden;
    transition: max-height 1s;
    // background-color: $gray-100;
}

.additional-projects-padding{
    padding-block: calc((100vw - 80vw)/2);

    display: flex;
    flex-direction: column;
    gap: 35px;
}

.additional-projects-container{
    
    .project {
        @include center-children-hor;
        position: relative;
        transition: all 0.6s ease-in-out, opacity 0.3s ease-in-out;
        cursor: pointer;
        transform-style: preserve-3d;
        margin: 0px;
        background: transparent;
        
    
    
        .logo-container {
            @include width-height-of(60%);
            @include center-children-hor;
            position: absolute;
            pointer-events: none;
            transform: translateZ(var(--z-offset));
            backface-visibility: hidden;
            -webkit-backface-visibility: hidden;
    
            .logo {
                @include width-height-of(100%);
                
            }
    
            .mf-logo{
                width: 100%;
            }
    
            .virtical-logo{
                height: 100%;
            }
        }
    
        .back::before {
            @include width-height-of(90%);
            position: absolute;
            content: '';
            border: 2px solid var(--gray-200);
            border-radius: 25px;
            pointer-events: none;
            transform: translateZ(var(--z-offset));
            backface-visibility: hidden;
            -webkit-backface-visibility: hidden;
        }
    }
    
    .project-rotater {
        @include center-children-hor;
        width: 100%;
        height: 100%;
        background-color: white;
        pointer-events: none;
        transform-style: preserve-3d;
        transition: all 0.6s ease-in-out, opacity 0.3s ease-in-out;
        box-shadow: 0px 0px 25px 10px rgba(40, 40, 40, 0.06);
        border-radius: 25px;
        
    
        &::before {
            @include width-height-of(90%);
            position: absolute;
            content: '';
            border: 2px solid var(--gray-200);
            border-radius: 25px;
            pointer-events: none;
            transform: translateZ(var(--z-offset));
            backface-visibility: hidden;
            -webkit-backface-visibility: hidden;
        }
    }
    
    .additional-project {
        transform: translateZ(100px);
        @include max-width-height-of(480px);
        @include width-height-of(var(--center-box-width-height));
    }
    
    .back {
        @include center-children-vir;
        @include width-height-of(100%);
        transform: rotateY(180deg) translateZ(var(--z-offset));
        pointer-events: none;
        position: relative;
        font-size: 100vw;
        transition: all 0.6s ease-in-out;
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
    
        .btn-default {
            margin-bottom: 0;
            margin-top: 1.5em;
            pointer-events: auto;
            font-size: 0.02em;
            width: 18em;
            height: 4em;
        }
    
        &::before {
            transition: all 0.6s ease-in-out;
        }
    }
    
    .back-title {
        font-size: 0.04em;
        padding-bottom: 1em;
    
        &::before {
            top: 1.5em;
            width: 2em;
            background-color: var(--pink);
        }
    }
    
    .back-description {
        font-size: 0.03em;
        margin: 0;
        margin-bottom: 0.5em;
        padding-inline: 10%;
    }
    
    .tools-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        width: 0.5em;
        height: 0.24em;

    }

    .tool {
        @include center-children-vir;
        margin: auto;
        align-items: center;
    }

    .tool-logo {
        img {
            width: 100%;
        }

        .logo {
            height: 0.08em;
        }
    }

    .tool-name {
        margin-top: 1em;
        font-size: 0.03em;
        white-space: nowrap;
    }

    .two-tools{
        display: flex;
        justify-content: center;

        .tool-logo .logo{
            height: 0.08em;
        }
    }
}

// Media Queries
@media (min-width: 576px) {
    .additional-project-button{
        bottom: 0px;
    }

    .additional-projects-padding{
        padding-block: 50px;
        flex-direction: row;
    }

	.additional-projects-container .additional-project {
		.project-rotater {
			box-shadow: 0px 0px 50px 10px rgba(40, 40, 40, 0.1);
		}

		.back-title {
			font-size: 0.015em;
			padding-bottom: 1em;

			&::before {
				top: 1.8em;
				width: 2em;
				background-color: var(--pink);
			}
		}

		.back-description {
			font-size: 0.012em;
			width: 25em;
			padding-inline: 5%;
		}

		.tools-container {
			width: 0.22em;
			height: 0.08em;
		}

		.tool {
			.tool-logo .logo {
				height: 0.035em;
			}

			.tool-name {
				font-size: 0.012em;
			}
		}

		.back .btn-default {
			margin-bottom: 0;
			margin-top: 1.5em;
			pointer-events: auto;
			font-size: 0.01em;
			width: 15em;
			height: 3.5em;
		}

        .btn-underline{
			margin-top: 1.5em;
		}

        .two-tools .tool-logo .logo{
            height: 0.03em;
        }
	}
}

@media (min-width: 1200px) {
    .additional-projects-padding{
        gap: 50px;
    }
	.additional-projects-container .additional-project {
		.back-title {
			font-size: 0.014em;
			padding-bottom: 1em;

			&::before {
				top: 1.2em;
				width: 2em;
				background-color: var(--pink);
			}
		}

		.back-description {
			font-size: 0.009em;
			width: 25em;
			padding-inline: 5%;
		}

		.tools-container {
			width: 0.16em;
			height: 0.08em;
		}

		.tool {
			.tool-logo .logo {
				height: 0.0275em;
				
			}

			.tool-name {
				font-size: 0.009em;
			}
		}

		.back .btn-default {
			margin-bottom: 0;
			margin-top: 1em;
			pointer-events: auto;
			font-size: 0.007em;
			width: 14em;
			height: 3.2em;
		}

        .two-tools .tool-logo .logo{
            height: 0.025em;
        }
	}
}

@media (min-width: 1920px) {
    .additional-projects-container .additional-project {
        .back{
            font-size: 1920px;
        }

        .project .logo-container, .project .back::before, .project-rotater::before  {
            transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 134.4, 1);
        }

        .back{
            transform: rotateY(180deg) matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 134.4, 1);
        }
    }
}

// Animations
.flip-button{
    transform: scaleY(-1);
}

.show-additional-projects{
    max-height: 1000px;
}