@use '../abstracts' as *;

.home-contact-button {
	display: flex;
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 2;
	width: 100vw;
	transform: translateZ(200px);
	// height: 10px;
	// background-color: red;
}

.contact-button {
	@include width-height-of(70px);
	@include center-children-hor;
	border-radius: 50px;
	position: absolute;
	bottom: 0;
	right: 0;
	border: none;
	background-color: var(--pink);
	color: white;
	font-size: 30px;
	margin: 20px 30px;
	cursor: pointer;
	transition: all 0.5s ease;
	transform: translateX(125px); // margin + width

	&:hover {
		transform: scale(1.1);
	}
}

.show-contact-button {
	transform: translateX(0px);
}

@media (min-width: 576px) {
	.contact-button {
		margin: 35px 55px;
	}
}
