@use 'abstracts' as *;

section.home-projects {
	position: relative;
	width: 100%;
	height: 100%;
	padding-top: var(--sections-padding-block);
	margin-bottom: 80px;

	.home-projects-container {
		@include center-children-vir;
		@include width-height-of(100%);
		position: relative;

		.section-title {
			position: absolute;
			top: 0;

			&::before {
				background-color: var(--pink);
			}
		}
	}
}

.purple-bar::before{
		background-color: var(--purple);
}

.orange-bar::before{
	background-color: var(--orange);
}

.pink-bar::before{
	background-color: var(--pink);
}



@media (min-width: 576px) {
	section.home-projects {
		margin-bottom: 0px;
	}
}